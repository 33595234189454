import styles from "./psychologistProfilePage.module.scss";
import { psychologists } from "../../util/constants";
import psyPromo from "../../resources/images/psyPromo.png";
import GoBackButton from "../../UI/GoBackButton";
import { ReactComponent as CheckSVG } from "../../resources/images/greenCheck.svg";
import VideoPlayer from "../../components/VideoPlayer";

const PsychologistProfilePage = () => {
  const {
    id,
    yearsOfExperience,
    lastName,
    firstName,
    priceHalfHour,
    priceHour,
    profilePicture,
    therapyMethods,
  } = psychologists[0];
  return (
    <div className={styles.container}>
      {/* <button className={styles.backButton}>Назад</button> */}
      <GoBackButton className={styles.backButton} />

      <img
        src={profilePicture}
        alt="Мария Соловьева"
        className={styles.profileImage}
      />
      <div className={styles.profileInfo}>
        <h2 className={styles.name}>
          {firstName} {lastName}
          <span className={styles.verifiedIcon}>
            <CheckSVG />
          </span>
        </h2>
        <p className={styles.experience}>{yearsOfExperience} лет опыта</p>
        <p className={styles.sessionInfo}>Индивидуальная сессия</p>
        <div className={styles.pricing}>
          <p>
            30 минут: <span className={styles.price}>{priceHalfHour}₽</span>
          </p>
          <p>
            60 минут: <span className={styles.price}>{priceHour}₽</span>
          </p>
        </div>
      </div>
      <VideoPlayer />
      <img src={psyPromo} className={styles.promo} alt="Промо" />

      <div className={styles.therapyMethods}>
        <h3>Методы терапии</h3>
        <div className={styles.methodTags}>
          <span className={styles.tag}>Транзактный анализ</span>
          <span className={styles.tag}>КПТ</span>
          <span className={styles.tag}>Кризисная психология</span>
          <span className={styles.tag}>Коучинг</span>
        </div>
      </div>

      <div className={styles.aboutSection}>
        <h3>О специалисте</h3>
        <p>
          {`Я кризисный психолог, работаю в интегративном подходе. Приглашаю Вас познакомиться со мной и моим способом взаимодействия с клиентами.Я
          достаточно легкий и поддерживающий психолог. Мне кажется, очень важно иметь возможность находиться в поддерживающей и принимающей среде. Особенно когда кажется, что всё плохо.\n\n`}
        </p>
        <p>
          {`В основном я работаю со взрослыми. Темы, с которыми ко мне приходят, это: \n– ощущение неуверенности, заниженная самооценка; \n– сложности в отношениях, развод, измена, недостаток друзей или общения; \n– нет удовольствия от жизни, апатия, отсутствие энергии; \n– тревога, страх, обида, злость, вина, стыд, отчаяние.\n\n`}
        </p>
        <p>
          {`Для меня взаимодействие с клиентом – это совместное исследование, путешествие и поиск экологичных и эффективных решений. Я как будто держу клиента за руку и помогаю ему увидеть свою силу. Даю четкие и конкретные инструменты, прямую обратную связь.`}
        </p>
      </div>

      <div className={styles.educationSection}>
        <h3>Образование</h3>
        <ul>
          <li className={styles.educationItem}>
            <p className={styles.educationItemTitle}>
              Бакалавриат, психология (2007-2012)
            </p>
            <p className={styles.educationItemText}>
              Московский Городской Психолого-Педагогический Университет,
              2007-2012 г.
            </p>
          </li>
          <li className={styles.educationItem}>
            <p className={styles.educationItemTitle}>Coaching basics (2018)</p>
            <p className={styles.educationItemText}>
              ФГБНУ «Психологический институт, 2018 г.
            </p>
          </li>
          <li className={styles.educationItem}>
            <p className={styles.educationItemTitle}>
              Транзактный анализ в тренинге (2018)
            </p>
            <p className={styles.educationItemText}>
              Национальный исследовательский институт дополнительного
              образования и профессионального обучения
            </p>
          </li>
          <li className={styles.educationItem}>
            <p className={styles.educationItemTitle}>
              Работа психолога в русле когнитивно-поведенческой терапии с
              клиентами, имеющими симптомы тревожного расстройства, фобий (2021){" "}
            </p>
            <p className={styles.educationItemText}>
              АНО ДПО Национальная академия
            </p>
          </li>
          <li className={styles.educationItem}>
            <p className={styles.educationItemTitle}>
              Коучинговый подход в развитии эмоционального интеллекта. Техники
              управления эмоциями и чувствами (2021)
            </p>
            <p className={styles.educationItemText}>
              АНО ДПО Национальная академия
            </p>
          </li>
          <li className={styles.educationItem}>
            <p className={styles.educationItemTitle}>
              Кризисная психология. Оказание психологической помощи в
              экстремальных ситуациях и при психологических травмах (2021)
            </p>
            <p className={styles.educationItemText}>
              АНО ДПО Национальная академия дополнительного профессионального
              образования
            </p>
          </li>
        </ul>
      </div>

      <div className={styles.faqSection}>
        <h3>Частые вопросы</h3>
        <div className={styles.faqItem}>
          <p>Как проходит первая сессия</p>
          <button className={styles.button}>
            <p>+</p>
          </button>
        </div>
        <div className={styles.faqItem}>
          <p>Как подготовиться к первой сессии</p>
          <button className={styles.button}>
            <p>+</p>
          </button>
        </div>
        <div className={styles.faqItem}>
          <p>Как часто нужно встречаться с психологом</p>
          <button className={styles.button}>
            <p>+</p>
          </button>
        </div>
        <div className={styles.faqItem}>
          <p>Сколько нужно сессий</p>
          <button className={styles.button}>
            <p>+</p>
          </button>
        </div>
        <div className={styles.faqItem}>
          <p>Как вести себя во время сессии</p>
          <button className={styles.button}>
            <p>+</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PsychologistProfilePage;
