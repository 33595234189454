import PsychologistCard from "../../components/PsychologistCard";
import styles from "./psychologistsPage.module.scss";
import PageNavigation from "../../UI/PageNavigation";
import { psychologists } from "../../util/constants";

const PsychologistsPage = () => {
  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.title}>Каталог психологов</h1>
        <div className={styles.content}>
          {psychologists.map((psychologist) => (
            <PsychologistCard psychologist={psychologist} />
          ))}
        </div>
      </div>
      <PageNavigation />
    </>
  );
};
export default PsychologistsPage;
