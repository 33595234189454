import { useSelector, useDispatch } from "react-redux";
import styles from "./GoBackButton.module.scss";
import cn from "classnames";
import Translator from "../../resources/localization/translator";
import { TELEGRAM } from "../../util/constants";
import { setQueryToggle } from "../../store/slices/calendarData";
import { sendMetrics } from "../../util/metrics";
import { useNavigate } from "react-router-dom";

interface Props {
  className?: string; // Проп для передачи пользовательских классов
  style?: React.CSSProperties; // Проп для передачи инлайн-стилей
}

interface RootState {
  userParams: UserParams;
}

const GoBackButton: React.FC<Props> = ({ className, style }) => {
  const { apiToken, language, viewedByAdmin } = useSelector(
    (state: RootState) => state.userParams
  );
  const { datesWithCheckup } = useSelector(
    (state: any) => state.datesWithCheckup
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDarkScheme = TELEGRAM.colorScheme === "dark";

  const goBackAndResetData = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(
        `/calendar/?token=${apiToken}&language=${language}&viewed_by_admin=${viewedByAdmin}`
      );
    }
    if (Object.keys(datesWithCheckup).length !== 0) {
      dispatch(setQueryToggle(false));
    }
    TELEGRAM.HapticFeedback.impactOccurred("heavy");
    if (typeof window.ym != "undefined") {
      sendMetrics("reachGoal", "click-go-back-btn");
    }
  };

  return (
    <button
      className={
        className
          ? className
          : cn(
              styles.goBackButton, // Использование дефолтного стиля только если нет пропса style
              { [styles.goBackButtonDark]: isDarkScheme }, // Условный стиль для тёмной темы
              className // Пользовательский класс, если он передан
            )
      }
      style={style || undefined} // Если передан проп style, то используем только его
      onClick={goBackAndResetData}
    >
      {Translator.translate("GO_BACK", language)}
    </button>
  );
};

export default GoBackButton;
