import { Psychologist } from "../../util/types";
import BigButton from "../../UI/BigButton";
import styles from "./psychologistCard.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as CheckSVG } from "../../resources/images/greenCheck.svg";

interface Props {
  psychologist: Psychologist;
}

const PsychologistCard = ({ psychologist }: Props) => {
  const {
    id,
    firstName,
    lastName,
    yearsOfExperience,
    priceHalfHour,
    priceHour,
    therapyMethods,
    profilePicture,
  } = psychologist;
  const { apiToken, language, viewedByAdmin } = useSelector(
    (state: UserParamsSlice) => state.userParams
  );
  return (
    <Link
      to={`/psychologists/${id}/?token=${apiToken}&language=${language}&viewed_by_admin=${viewedByAdmin}`}
      className={styles.card}
    >
      <div className={styles.imageContainer}>
        <img
          src={profilePicture}
          alt="Мария Соловьева"
          className={styles.image}
        />
      </div>
      <div className={styles.content}>
        <h2 className={styles.name}>
          <p>
            {firstName} {lastName}
          </p>
          <span className={styles.verifiedIcon}>
            <CheckSVG />
          </span>
        </h2>
        <p className={styles.experience}>{yearsOfExperience} лет опыта</p>
        <div className={styles.sessionInfo}>
          <p>Индивидуальная сессия</p>
          <div className={styles.prices}>
            <span>30 минут: {priceHalfHour}₽</span>
            <span>60 минут: {priceHour}₽</span>
          </div>
        </div>
        <div className={styles.methods}>
          <h3>Методы терапии</h3>
          <div className={styles.methodTags}>
            {therapyMethods.map((method) => (
              <span>{method}</span>
            ))}
          </div>
        </div>
        <BigButton title="Подробнее о психологе" />
      </div>
    </Link>
  );
};
export default PsychologistCard;
