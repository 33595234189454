import { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import CalendarPage from "../../pages/CalendarPage";
import CheckupSkeleton from "../CheckupSkeleton";
import { API, TELEGRAM } from "../../util/constants";
import ChartPage from "../../pages/ChartPage";
import {
  setApiToken,
  setColorScheme,
  setLanguage,
  setShareToken,
  setThemeParams,
  setViewedByAdmin,
} from "../../store/slices/userParams";
import { useDispatch, useSelector } from "react-redux";
import SharingPage from "../../pages/SharingPage/index";
import {
  fetchViewableDiaries,
  setActiveProperties,
  setActiveUserName,
  setActiveUserPicId,
  setActiveUserTgUsername,
  setDiaryOwnerName,
  setDiaryOwnerPicId,
  setDiaryOwnerTgUsername,
  setIsOwnerDiary,
  setIsUserLoaded,
} from "../../store/slices/sharingData";
import { setResetDataToggle } from "../../store/slices/calendarData";
import PsychologistsPage from "../../pages/PsychologistsPage";
import PsychologistProfilePage from "../../pages/PsychologistProfilePage";

const CheckupPage = lazy(() => import("../../pages/CheckupPage"));

function App() {
  const [data, setData] = useState();
  const [openAppToggle, setOpenAppToggle] = useState(true);
  const dispatch = useDispatch();
  const location = window.location.search;
  const params = new URLSearchParams(location);
  const apiToken = params.get("token");
  const lang = params.get("language");
  const shareToken = params.get("to_shared_diary");
  const viewedByAdmin = params.get("viewed_by_admin");

  const { viewableDiaries } = useSelector((state) => state.sharingData);
  const { startPage } = useSelector((state) => state.userParams);

  useEffect(() => {
    TELEGRAM.isVerticalSwipesEnabled = false;
    TELEGRAM.expand();
    TELEGRAM.setBackgroundColor("bg_color");
    TELEGRAM.ready();
    dispatch(setColorScheme(TELEGRAM.colorScheme));
    dispatch(setThemeParams(TELEGRAM.themeParams));
    dispatch(setLanguage(lang));
    dispatch(setApiToken(apiToken));
    dispatch(setViewedByAdmin(viewedByAdmin));
    dispatch(setShareToken(shareToken));
    setData(TELEGRAM.initData);
  }, [data]);

  useEffect(() => {
    if (apiToken) {
      dispatch(
        fetchViewableDiaries({
          apiToken: apiToken,
        })
      );
    }
  }, [apiToken]);

  useEffect(() => {
    if (viewableDiaries && "diaries" in viewableDiaries) {
      dispatch(
        setDiaryOwnerPicId(viewableDiaries.diaries[0]?.profile_pic_file_id)
      );
      dispatch(setDiaryOwnerName(viewableDiaries.diaries[0]?.name));
      dispatch(
        setDiaryOwnerTgUsername(viewableDiaries.diaries[0]?.tg_username)
      );
      if (!shareToken) {
        dispatch(
          setActiveUserPicId(viewableDiaries.diaries[0]?.profile_pic_file_id)
        );
        dispatch(setActiveUserName(viewableDiaries.diaries[0]?.name));
        dispatch(
          setActiveUserTgUsername(viewableDiaries.diaries[0]?.tg_username)
        );
      } else {
        if (viewableDiaries && "diaries" in viewableDiaries) {
          viewableDiaries.diaries.forEach((item) => {
            if (item.token === shareToken) {
              dispatch(setActiveUserPicId(item.profile_pic_file_id));
              dispatch(setActiveUserName(item.name));
              dispatch(setActiveUserTgUsername(item.tg_username));
              dispatch(setActiveProperties(item.access));
              dispatch(setIsOwnerDiary(false));
              dispatch(setResetDataToggle(true));
            }
          });
        }
      }
      dispatch(setIsUserLoaded(true));
    }
  }, [viewableDiaries]);

  const logWebappOpen = async (data) => {
    try {
      const response =
        await API.logWebappAction.logWebappActionLogWebappActionPost(
          {
            api_token: apiToken,
          },
          {
            start_page: startPage,
            data: TELEGRAM.initDataUnsafe,
            themeParams: TELEGRAM.themeParams,
            device: {
              screenWidth: window.screen.width,
              screenHeight: window.screen.height,
              platform: TELEGRAM.platform,
            },
          }
        );

      return response;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return error;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (openAppToggle && !viewedByAdmin && startPage) {
        logWebappOpen();
        setOpenAppToggle(false);
      }
    }, 2000);
  }, [startPage]);

  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path={`/calendar`} element={<CalendarPage />} />
          <Route
            path="/checkup/:checkupDate"
            element={
              <Suspense fallback={<CheckupSkeleton />}>
                <CheckupPage />
              </Suspense>
            }
          />
          <Route path={"/statistics"} element={<ChartPage />} />
          <Route path={"/sharing"} element={<SharingPage />} />
          <Route path={"/psychologists"} element={<PsychologistsPage />} />
          <Route
            path={"/psychologists/:id"}
            element={<PsychologistProfilePage />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
