import { useRef, useState, useEffect } from "react";
import styles from "./videoPlayer.module.scss";

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const progressRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [progress, setProgress] = useState(0); // Новое состояние для прогресса

  // Обновление прогресса и времени воспроизведения
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const updateProgress = () => {
        const currentProgress = (video.currentTime / video.duration) * 100;
        setCurrentTime(video.currentTime);
        setProgress(currentProgress);
      };
      video.addEventListener("timeupdate", updateProgress);

      return () => video.removeEventListener("timeupdate", updateProgress);
    }
  }, []);

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    videoRef.current.volume = newVolume;
  };

  const handleProgressChange = (e) => {
    const newTime = (e.target.value / 100) * duration;
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
  };

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <div className={styles.videoContainer}>
      <video
        ref={videoRef}
        className={styles.videoPlayer}
        onLoadedMetadata={() => setDuration(videoRef.current.duration)}
        onEnded={handleVideoEnd}
      >
        <source
          src="https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      <div className={styles.controls}>
        <button onClick={handlePlayPause} className={styles.playButton}>
          {isPlaying ? "Pause" : "Play"}
        </button>

        {/* Прогресс-бар с кастомной заливкой */}
        <input
          type="range"
          className={styles.progressBar}
          value={progress}
          onChange={handleProgressChange}
          style={{
            background: `linear-gradient(to right, #2196F3 ${progress}%, #444 ${progress}%)`,
          }}
        />

        {/* <div className={styles.time}>
          {formatTime(currentTime)} / {formatTime(duration)}
        </div> */}

        {/* <input
          type="range"
          className={styles.volumeControl}
          value={volume}
          min="0"
          max="1"
          step="0.01"
          onChange={handleVolumeChange}
        /> */}

        <button onClick={toggleFullscreen} className={styles.fullscreenButton}>
          {isFullscreen ? "-" : "+"}
        </button>
      </div>
    </div>
  );
};

export default VideoPlayer;
