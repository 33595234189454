import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Translator from "../../resources/localization/translator";
import { setCurrentPage } from "../../store/slices/navigation";
import { API, TELEGRAM } from "../../util/constants";
import { sendMetrics } from "../../util/metrics";
import "./PageNavigation.scss";
import { setQueryToggle } from "../../store/slices/calendarData";

const PageNavigation = () => {
  const dispatch = useDispatch();
  const { apiToken, language, viewedByAdmin, shareToken } = useSelector(
    (state) => state.userParams
  );
  const { datesWithCheckup } = useSelector((state) => state.datesWithCheckup);
  const { currentPage } = useSelector((state) => state.pageNavigation);

  const { isOwnerDiary } = useSelector((state) => state.sharingData);

  const openSettings = async (data) => {
    try {
      const response =
        await API.openDiarySettings.openDiarySettingsPostOpenDiarySettingsPost({
          api_token: apiToken,
        });

      return response;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return error;
    }
  };
  const sendData = () => {
    TELEGRAM.HapticFeedback.impactOccurred("heavy");
    sendMetrics("reachGoal", "click-settings-btn");
    if (!viewedByAdmin) {
      openSettings();
    }
    setTimeout(() => {
      TELEGRAM.close();
    }, 0);
  };
  const clickHandler = () => {
    if (clickHandler.cantClick) return;
    clickHandler.cantClick = true;

    sendData();

    setTimeout(() => {
      clickHandler.cantClick = false;
    }, 500);
  };
  const setActivePage = (page) => {
    TELEGRAM.HapticFeedback.impactOccurred("heavy");
    dispatch(setCurrentPage(page));
    switch (page) {
      case "calendar":
        if (Object.keys(datesWithCheckup).length !== 0) {
          dispatch(setQueryToggle(false));
        }
        sendMetrics("reachGoal", "click-nav-calendar-btn");
        break;
      case "statistics":
        sendMetrics("reachGoal", "click-nav-statistics-btn");
        break;
      default:
        break;
    }
  };

  return (
    <>
      {isOwnerDiary && (
        <div className="navigation">
          <Link
            to={`/calendar/?token=${apiToken}&language=${language}&viewed_by_admin=${viewedByAdmin}`}
          >
            <button
              className={`navigation__item${
                currentPage === "calendar" ? "_active" : ""
              }`}
              onClick={() => setActivePage("calendar")}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="5" />
                <path
                  d="M10.1053 24.4211C9.6421 24.4211 9.24547 24.2563 8.91537 23.9267C8.58582 23.5966 8.42105 23.2 8.42105 22.7368V10.9474C8.42105 10.4842 8.58582 10.0879 8.91537 9.75832C9.24547 9.42821 9.6421 9.26316 10.1053 9.26316H10.9474V7.57895H12.6316V9.26316H19.3684V7.57895H21.0526V9.26316H21.8947C22.3579 9.26316 22.7545 9.42821 23.0846 9.75832C23.4142 10.0879 23.5789 10.4842 23.5789 10.9474V22.7368C23.5789 23.2 23.4142 23.5966 23.0846 23.9267C22.7545 24.2563 22.3579 24.4211 21.8947 24.4211H10.1053ZM10.1053 22.7368H21.8947V14.3158H10.1053V22.7368Z"
                  fill="#F5F5F5"
                />
              </svg>
              {Translator.translate("CALENDAR_BUTTON", language)}
            </button>
          </Link>
          <Link
            to={`/statistics/?token=${apiToken}&language=${language}&viewed_by_admin=${viewedByAdmin}`}
          >
            <button
              className={`navigation__item${
                currentPage === "statistics" ? "_active" : ""
              }`}
              onClick={() => setActivePage("statistics")}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="5" fill="#F8F8F8" />
                <rect
                  className="plot"
                  x="6"
                  y="6"
                  width="6"
                  height="19"
                  fill="#7868E6"
                />
                <rect
                  className="plot"
                  x="13"
                  y="14"
                  width="6"
                  height="11"
                  fill="#7868E6"
                />
                <rect
                  className="plot"
                  x="20"
                  y="9"
                  width="6"
                  height="16"
                  fill="#7868E6"
                />
              </svg>
              {Translator.translate("STATISTICS_BUTTON", language)}
            </button>
          </Link>
          <Link
            to={`/psychologists?token=${apiToken}&language=${language}&viewed_by_admin=${viewedByAdmin}`}
          >
            <button className="navigation__item">
              {/* onClick={clickHandler} */}
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="5" fill="#7868E6" />
                <path
                  d="M14.75 23C14.75 23 13.5 23 13.5 21.75C13.5 20.5 14.75 16.75 19.75 16.75C24.75 16.75 26 20.5 26 21.75C26 23 24.75 23 24.75 23H14.75ZM19.75 15.5C20.7446 15.5 21.6984 15.1049 22.4017 14.4017C23.1049 13.6984 23.5 12.7446 23.5 11.75C23.5 10.7554 23.1049 9.80161 22.4017 9.09835C21.6984 8.39509 20.7446 8 19.75 8C18.7554 8 17.8016 8.39509 17.0983 9.09835C16.3951 9.80161 16 10.7554 16 11.75C16 12.7446 16.3951 13.6984 17.0983 14.4017C17.8016 15.1049 18.7554 15.5 19.75 15.5ZM12.52 23C12.3348 22.6097 12.2424 22.1819 12.25 21.75C12.25 20.0562 13.1 18.3125 14.67 17.1C13.8865 16.858 13.07 16.74 12.25 16.75C7.25 16.75 6 20.5 6 21.75C6 23 7.25 23 7.25 23H12.52ZM11.625 15.5C12.4538 15.5 13.2487 15.1708 13.8347 14.5847C14.4208 13.9987 14.75 13.2038 14.75 12.375C14.75 11.5462 14.4208 10.7513 13.8347 10.1653C13.2487 9.57924 12.4538 9.25 11.625 9.25C10.7962 9.25 10.0013 9.57924 9.41529 10.1653C8.82924 10.7513 8.5 11.5462 8.5 12.375C8.5 13.2038 8.82924 13.9987 9.41529 14.5847C10.0013 15.1708 10.7962 15.5 11.625 15.5Z"
                  fill="#F8F8F8"
                />
              </svg>
              {Translator.translate("PSYCHOLOGISTS_BUTTON", language)}
            </button>
          </Link>
        </div>
      )}
    </>
  );
};
export default PageNavigation;
